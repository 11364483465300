import { makeAutoObservable } from "mobx"

class DocModalStoreImpl {
  privacyIsOpened: boolean = false
  consentPersonalDataIsOpened: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setPrivacyOpened(state: boolean) {
    this.privacyIsOpened = state
  }

  setConsentPersonalDataOpened(state: boolean) {
    this.consentPersonalDataIsOpened = state
  }
}

export const DocModalStore = new DocModalStoreImpl()
