import axios, { type AxiosError } from "axios"
import { API_URL } from "shared/config"
import { GeneralResponse } from "../types/common"

// ----------------------------------------------------------------------

type OriginalRequest = AxiosError["config"] & {
  _retry?: boolean
} | undefined

export const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    void Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error: AxiosError) => {
    const originalRequest: OriginalRequest = error.config

    if (error.response?.status === 401 && originalRequest && originalRequest.url === "api/v1/auth/refresh") {
      return Promise.reject(error)
    }

    if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
      originalRequest._retry = true

      const { data: { payload } } = await axios.get<GeneralResponse<{
        accessToken: string
      }>>(`${API_URL}/api/v1/auth/refresh`, { withCredentials: true })
      const { accessToken } = payload

      if (accessToken) {
        localStorage.setItem("accessToken", accessToken)
        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        return api.request(originalRequest)
      }
    }

    return Promise.reject(error)
  },
)
