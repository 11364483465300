import { makeObservableStore } from "../../shared/utils"
import { runInAction } from "mobx"
import { Gallery } from "../../shared/types/Gallery"

export interface GalleryStore {
  isLoading: boolean
  error: string | null
  totalCount: number
  gallery: Gallery
}

export const galleryStore = makeObservableStore<GalleryStore>({
  isLoading: false,
  error: null,
  totalCount: 0,
  gallery: {
    images: [],
  },
})

export function resetGalleryStore() {
  runInAction(() => {
    galleryStore.isLoading = false
    galleryStore.error = null
    galleryStore.totalCount = 0
    galleryStore.gallery = {
      images: [],
    }
  })
}
