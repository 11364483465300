const ROOTS = {
  AUTH: "/auth",
  DASHBOARD: "/dashboard",
  ERROR: "/error",
  DOCS: "/docs",
}

// ----------------------------------------------------------------------

export const PATHS = {
  ROOT: "/",

  PROJECTS: {
    ROOT: "/projects",
    DETAILS: (id: string): string => `/projects/${id}`,
  },

  BLOG: {
    ROOT: "/blog",
    DETAILS: (articleId: string): string => `/blog/${articleId}`,
  },

  AUTH: {
    LOGIN: `${ROOTS.AUTH}/login`,
  },

  ERROR: {
    403: `${ROOTS.ERROR}/403`,
    404: `${ROOTS.ERROR}/404`,
    500: `${ROOTS.ERROR}/500`,
  },

  DASHBOARD: {
    PROJECT: {
      ROOT: `${ROOTS.DASHBOARD}/projects`,
      CREATE: `${ROOTS.DASHBOARD}/projects/create`,
      EDIT: (id: string): string => `${ROOTS.DASHBOARD}/projects/${id}`,
    },
    GALLERY: {
      ROOT: `${ROOTS.DASHBOARD}/gallery`,
    },
    BLOG: {
      ROOT: `${ROOTS.DASHBOARD}/blog`,
      CREATE: `${ROOTS.DASHBOARD}/blog/create`,
      EDIT: (articleId: string): string => `${ROOTS.DASHBOARD}/blog/${articleId}`,
    },
    EMPLOYEE: {
      ROOT: `${ROOTS.DASHBOARD}/team`,
      CREATE: `${ROOTS.DASHBOARD}/team/create`,
      EDIT: (id: string): string => `${ROOTS.DASHBOARD}/team/${id}`,
    },
  },

  DOCS: {
    ROOT: ROOTS.DOCS,

    COOKIES: `${ROOTS.DOCS}/cookies`,
    OFFER: `${ROOTS.DOCS}/offer`,
    PRIVACY_POLICY: `${ROOTS.DOCS}/privacy-policy`,
    AFFILIATE_PROGRAM: "/affiliate-program",
  },
}
