import {
  Alert,
  Container,
  createStyles,
  Modal,
  ScrollArea,
  Stack,
  TextInput,
  Text,
  Input,
  Button,
} from "@mantine/core"
import { observer } from "mobx-react-lite"
import { FormModalService } from "../app/service"
import { DocModalStore, formModalStore, resetFormModalStore } from "../app/store"
import * as Yup from "yup"
import { useMediaQuery } from "@mantine/hooks"
import React, { useEffect } from "react"
import { useForm, yupResolver } from "@mantine/form"
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react"
import { IMaskInput } from "react-imask"
import { is } from "@babel/types"

const useStyles = createStyles((theme) => ({
  modalContent: {
    width: "100%",
    overflow: "hidden",
    borderRadius: theme.radius.lg,
    maxWidth: 640,

    [theme.fn.smallerThan(1024)]: {
      maxWidth: 480,
    },
  },

  modalHeader: {
    padding: "32px 32px 24px",

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalBody: {
    padding: 32,

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalTitle: {
    fontWeight: 700,
    fontSize: 24,

    [theme.fn.smallerThan("md")]: {
      fontWeight: 600,
      fontSize: 20,
    },
  },
}))

const schema = Yup.object().shape({
  fullName: Yup.string().required("Имя и фамилия обязательны"),
  phone: Yup.string().required("Телефон обязателен").length(18, "Пожалуйста, укажите телефон полностью"),
})

export const FormModal = observer(function FormModal() {
    const { classes } = useStyles()
    const matched = useMediaQuery(`(max-width: 768px)`)
    const is425 = useMediaQuery(`(max-width: 425px)`)
    const { topic = "Заявка на консультацию", tag = "консультация", error, isLoading, isSuccess, isOpened } = formModalStore

    const close = () => FormModalService.setIsOpened(false)
    const openModal = () => DocModalStore.setPrivacyOpened(true)

    const form = useForm({
      validateInputOnChange: true,
      validate: yupResolver(schema),
      initialValues: {
        fullName: "",
        phone: "",
      },
    })

    const onSubmit = (values: typeof form.values): void => {
      if (!form.isDirty() || !form.isValid()) {
        return
      }

      void FormModalService.send({ ...values, topic, tag })
    }

    useEffect(() => {
      form.reset()
      resetFormModalStore()
    }, [ isOpened ])

    return (
      <Modal.Root
        opened={isOpened}
        onClose={close}
        zIndex={4500}
        centered
        scrollAreaComponent={ScrollArea.Autosize}
        size="auto"
      >
        <Modal.Overlay/>
        <Modal.Content className={classes.modalContent}>
          <Modal.Header className={classes.modalHeader}>
            <Modal.Title className={classes.modalTitle}>Заявка на обратный звонок</Modal.Title>
            <Modal.CloseButton variant="light" radius="lg" size="xl"/>
          </Modal.Header>
          <Modal.Body className={classes.modalBody}>

            {isSuccess ? (
                <Container p={0} size={560}>
                  <Alert
                    styles={{ title: { fontSize: 18 }, message: { fontSize: 16 } }}
                    icon={<IconCircleCheck/>}
                    title="Заявка успешно отправлена!"
                    color="magenta"
                    radius="lg"
                  >
                    В ближайшее время с Вами свяжется наш сотрудник и ответит на все Вами вопросы.
                  </Alert>
                </Container>
              )
              : (
                <Container p={0} size={560}>
                  <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack spacing={is425 ? "md" : "xl"}>
                      <Text size="md" c="dimmed">Мы свяжимся с Вами в ближайшее
                        время и проконсультируем Вас по вашему вопросу.
                      </Text>
                      <TextInput
                        disabled={isLoading}
                        placeholder="Имя"
                        radius="lg"
                        size={matched ? "md" : "lg"}
                        {...form.getInputProps("fullName")}
                      />
                      <Input.Wrapper id="participant-form-phone" error={form.errors["phone"]}
                                     size={matched ? "md" : "lg"}>
                        <Input
                          disabled={isLoading}
                          component={IMaskInput}
                          mask="+7 (000) 000-00-00"
                          id="participant-form-phone"
                          placeholder="+7 (918) 000-00-00"
                          radius="lg"
                          size={matched ? "md" : "lg"}
                          {...form.getInputProps("phone")}
                        />
                      </Input.Wrapper>

                      <Button
                        loading={isLoading}
                        type="submit"
                        fullWidth
                        radius="lg"
                        size={matched ? "md" : "lg"}
                      >
                        Оставить заявку
                      </Button>

                      <Text fz="xs" c="dimmed">
                        Отправляя форму, Вы соглашаетесь с нашей &nbsp;
                        <Text underline c="dark" onClick={openModal} sx={{ cursor: "pointer" }} span>
                          политикой конфиденциальности
                        </Text>
                      </Text>

                      {error && (
                        <Alert
                          icon={<IconAlertCircle/>}
                          title="Ошибка отправки данных"
                          color="red"
                          radius="lg"
                        >
                          {error}
                        </Alert>
                      )}

                    </Stack>
                  </form>
                </Container>
              )}

          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    )
  },
)
