import {
  IconCrane,
  IconMessages,
  IconHome2,
  IconUsers,
  IconBlockquote,
  IconProps,
  Icon,
} from "@tabler/icons-react"
import { type RichTextEditorLabels } from "@mantine/tiptap"
import { ArticleCategory } from "shared/types/article"
import { PATHS } from "./paths"
import React from "react"

// ----------------------------------------------------------------------

export const categoryNames: Record<ArticleCategory, string> = {
  [ArticleCategory.roofing]: "Кровля",
  [ArticleCategory.finishing]: "Отделочные работы",
  [ArticleCategory.masonry]: "Кладка",
}

export type MainNavigateRoute = {
  label: string
  internal?: boolean
  linkTo: string
  Icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<Icon>>
}

export const mainNavigateRoutes: Array<MainNavigateRoute> = [
  { label: "Проекты", linkTo: PATHS.PROJECTS.ROOT, Icon: IconCrane },
  { label: "Построенные дома", linkTo: "gallery", Icon: IconHome2, internal: true },
  { label: "Наша команда", linkTo: "team", Icon: IconUsers, internal: true },
  { label: "Отзывы", linkTo: "reviews", Icon: IconMessages, internal: true },
  { label: "Блог", linkTo: PATHS.BLOG.ROOT, Icon: IconBlockquote },
]

export const DEFAULT_LABELS: RichTextEditorLabels = {
  // Controls labels
  linkControlLabel: "Ссылка",
  colorPickerControlLabel: "Цвет текста",
  highlightControlLabel: "Выделить текст",
  colorControlLabel: (color) => `Установить цвет текста ${color}`,
  boldControlLabel: "Жирный",
  italicControlLabel: "Курсив",
  underlineControlLabel: "Подчеркнутый",
  strikeControlLabel: "Зачеркнутый",
  clearFormattingControlLabel: "Очистить форматирование",
  unlinkControlLabel: "Удалить ссылку",
  bulletListControlLabel: "Маркированный список",
  orderedListControlLabel: "Упорядоченный список",
  h1ControlLabel: "Заголовок 1",
  h2ControlLabel: "Заголовок 2",
  h3ControlLabel: "Заголовок 3",
  h4ControlLabel: "Заголовок 4",
  h5ControlLabel: "Заголовок 5",
  h6ControlLabel: "Заголовок 6",
  blockquoteControlLabel: "Цитата",
  alignLeftControlLabel: "Выравнивание текста: слева",
  alignCenterControlLabel: "Выравнивание текста: по-центру",
  alignRightControlLabel: "Выравнивание текста: справа",
  alignJustifyControlLabel: "Выравнивание текста: по ширине",
  codeControlLabel: "Код",
  codeBlockControlLabel: "Блок кода",
  subscriptControlLabel: "Подстрочный индекс",
  superscriptControlLabel: "Надстрочный индекс",
  unsetColorControlLabel: "Сбросить цвет",
  hrControlLabel: "Горизонтальная линия",

  // Link editor
  linkEditorInputLabel: "Введите URL",
  linkEditorInputPlaceholder: "https://example.com/",
  linkEditorExternalLink: "Открыть ссылку в новой вкладке",
  linkEditorInternalLink: "Открыть ссылку в той же вкладке",
  linkEditorSave: "Сохранить",

  // Color picker control
  colorPickerCancel: "Отмена",
  colorPickerClear: "Отменить выбор цвета",
  colorPickerColorPicker: "Выбор цвета",
  colorPickerPalette: "Цветовая палитра",
  colorPickerSave: "Сохранить",
  colorPickerColorLabel: (color) => `Установить цвет текста ${color}`,
}
