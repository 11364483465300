import { createStyles, Text } from "@mantine/core"
import { Header } from "widgets/header"
import { Footer } from "widgets/footer"
import { observer } from "mobx-react-lite"
import { PropsWithChildren } from "react"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  rootWrapper: {
    boxSizing: "border-box",
    minWidth: 320,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  wrapper: {
    display: "flex",
    flex: 1,
    boxSizing: "border-box",
  },

  main: {
    flex: 1,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  info: {
    margin: "0 auto",
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 16,
    paddingBottom: 0,

    [theme.fn.smallerThan(900)]: {
      display: "none",
    },
  },
}))

// ----------------------------------------------------------------------

export const MainLayout = observer(function MainLayout({ children }: PropsWithChildren) {
  const { classes } = useStyles()

  return (
    <section className={classes.rootWrapper}>
      <div className={classes.info}>
        <Text c="dimmed">
          <Text color="dark" fw={700} span>+7 (918) 005 75 13</Text> | С 9:00 до 22:00 | Без выходных
        </Text>
      </div>
      <Header/>
      <section className={classes.wrapper}>
        <main className={classes.main}>{children}</main>
      </section>
      <Footer/>
    </section>
  )
})
