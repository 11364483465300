import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type  ErrorResponse, GeneralResponse } from "../types/common"
import { Employee } from "../types/Employee"

// ----------------------------------------------------------------------

export function getTeam(): Promise<AxiosResponse<GeneralResponse<Array<Employee>>, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.employee}/list`)
}

export function getEmployeeById(id: string): Promise<AxiosResponse<GeneralResponse<Employee>, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.employee}/${id}`)
}

export function updateEmployee(id: string, body: FormData): Promise<AxiosResponse<GeneralResponse<Employee>, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.employee}/${id}`, body)
}

export function createEmployee(body: FormData): Promise<AxiosResponse<GeneralResponse<Employee>, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.employee}/create`, body)
}

export function removeEmployee(id: string): Promise<AxiosResponse<GeneralResponse, ErrorResponse>> {
  return api.delete(`${API_ENDPOINTS.employee}/${id}`)
}
