import { runInAction } from "mobx"
import { Article, ArticleParams } from "../../../shared/types/article"
import { makeObservableStore } from "../../../shared/utils"

export interface ArticleStore {
  isLoading: boolean
  error: string | null
  totalCount: number
  articles: Array<Article>
  articlesOnMain: Array<Article>
  params: ArticleParams
}

export const articleStore = makeObservableStore<ArticleStore>({
  isLoading: false,
  error: null,
  totalCount: 0,
  articles: [],
  articlesOnMain: [],
  params: {
    page: 1,
    perPage: 9,
  },
})

export function resetArticleStore() {
  console.log("Reset article store")

  runInAction(() => {
    articleStore.isLoading = false
    articleStore.error = null
    articleStore.totalCount = 0
    articleStore.articles = []
    articleStore.articlesOnMain = []
    articleStore.params = {
      page: 1,
      perPage: 9,
    }
  })
}
