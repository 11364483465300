import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type ErrorResponse, GeneralResponse } from "../types/common"
import { CalculatorRequest, CommonRequest, VacancyRequest } from "../types/crm"

// ----------------------------------------------------------------------

export function sendVacancyRequest(body: VacancyRequest): Promise<AxiosResponse<GeneralResponse, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.crm}/vacancy`, body)
}

export function sendCalculatorRequest(body: CalculatorRequest): Promise<AxiosResponse<GeneralResponse, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.crm}/calculator`, body)
}

export function sendCommonRequest(body: CommonRequest): Promise<AxiosResponse<GeneralResponse, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.crm}/common`, body)
}
