import { makeAutoObservable, runInAction } from "mobx"
import { login as apiLogin, logout as apiLogout, refresh } from "shared/api/auth"
import { type LoginBody } from "shared/types/auth"
import { type User } from "../../shared/types/user"
import { umbrellaException } from "../../shared/lib/umbrellaException"

// ----------------------------------------------------------------------

class AuthStoreImpl {
  error: string | null = null
  isLoading: boolean = false

  user: User | null = null
  isAuthenticated = false
  accessToken: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  login = async ({ email, password }: LoginBody) => {
    try {
      this.isLoading = true
      this.error = null
      localStorage.removeItem("accessToken")

      const { data: { payload } } = await apiLogin({ email, password })

      runInAction(() => {
        this.user = payload.user
        this.isAuthenticated = true

        localStorage.setItem("accessToken", payload.accessToken)
        this.accessToken = payload.accessToken

        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  logout = async () => {
    try {
      this.isLoading = true
      this.error = null

      await apiLogout()

      runInAction(() => {
        this.user = null
        this.isAuthenticated = false

        localStorage.removeItem("accessToken")
        this.accessToken = null

        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  checkAuth = async () => {
    try {
      this.isLoading = true

      const { data: { payload } } = await refresh()

      runInAction(() => {
        this.user = payload.user
        this.isAuthenticated = true

        localStorage.setItem("accessToken", payload.accessToken)
        this.accessToken = payload.accessToken

        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }
}

export const AuthStore = new AuthStoreImpl()
