import { createStyles, Flex, Text } from "@mantine/core"
import { Link } from "react-router-dom"
import logo from "shared/assets/logo.png"
import React from "react"
import { useMediaQuery } from "@mantine/hooks"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme, { admin }: { admin: boolean }) => ({
  link: {
    textDecoration: "none",
  },

  image: {
    maxHeight: admin ? 44 : "100%",
    objectFit: "contain",
    verticalAlign: "middle",

    [theme.fn.smallerThan(900)]: {
      maxHeight: 44,
    },
  },

  mainText: {
    color: theme.black,
    fontWeight: 900,
    fontSize: admin ? 20 : 29,
    letterSpacing: admin ? "unset" : 1,
    textTransform: "uppercase",
  },


  secondText: {
    lineHeight: 1,
    textTransform: "uppercase",
  },

  textAccent: {
    fontWeight: 900,
    fontSize: admin ? 20 : 29,
    letterSpacing: admin ? "unset" : 1,
    textTransform: "uppercase",
  },
}))

type Props = {
  className?: string
  admin?: boolean
};

export function Logo({ className = "", admin = false }: Props): React.JSX.Element {
  const { classes, theme } = useStyles({ admin })
  const matches = useMediaQuery(`(min-width: 900px)`)

  function renderText() {
    if (admin) {
      return (
        <Flex direction="column" ml={16}>
          <Text
            lh={1}
            className={classes.textAccent}
            variant="gradient"
            gradient={{ from: "#26b0ba", to: "#2f70a7", deg: 50 }}
          >
            СТРОЙ
          </Text>
          <Text lh={1} className={classes.mainText}>
            ЭКСПРЕСС
          </Text>
        </Flex>
      )
    }

    if (matches) {
      return (
        <Flex direction="column" ml={16}>
          <Flex>
            <Text
              className={classes.textAccent}
              variant="gradient"
              gradient={{ from: "#26b0ba", to: "#2f70a7", deg: 50 }}
            >
              СТРОЙ
            </Text>
            <Text className={classes.mainText}>
              ЭКСПРЕСС
            </Text>
          </Flex>
          <Text c="dimmed" className={classes.secondText}>
            Быстро. Качественно. Надежно.
          </Text>
        </Flex>
      )
    }

    return null
  }

  return (
    <Link to="/" className={`${classes.link} ${className}`}>
      <Flex align="center">
        <img className={classes.image} src={logo} alt="Логотип Body Coach"/>
        {renderText()}
      </Flex>
    </Link>
  )
}
