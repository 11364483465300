import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type ErrorResponse, GeneralResponse } from "../types/common"
import { Gallery } from "../types/Gallery"

// ----------------------------------------------------------------------

export function getGallery(): Promise<AxiosResponse<GeneralResponse<Gallery>, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.gallery}/list`)
}

export function updateGallery(body: FormData): Promise<AxiosResponse<GeneralResponse, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.gallery}/update`, body)
}
