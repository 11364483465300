import { ProjectAreaFilter, projectFiltersStore, ProjectFiltersStore, ProjectFloorFilter } from "../store"
import { runInAction } from "mobx"

class ProjectFiltersServiceImpl {

  setArea(value: ProjectAreaFilter): void {
    runInAction(() => {
      if (this.projectFiltersStore.area.has(value)) {
        this.projectFiltersStore.area.delete(value)
        return
      }

      this.projectFiltersStore.area.add(value)
    })
  }

  setFloor(value: ProjectFloorFilter): void {
    runInAction(() => {
      if (this.projectFiltersStore.numberOfFloors.has(value)) {
        this.projectFiltersStore.numberOfFloors.delete(value)
        return
      }

      this.projectFiltersStore.numberOfFloors.add(value)
    })
  }

  constructor(
    private projectFiltersStore: ProjectFiltersStore,
  ) {
  }
}

export const ProjectFiltersService = new ProjectFiltersServiceImpl(projectFiltersStore)
