import { makeObservableStore } from "../../../shared/utils"
import { runInAction } from "mobx"

export enum ProjectAreaFilter {
  area_filter_1 = "area_filter_1",
  area_filter_2 = "area_filter_2",
  area_filter_3 = "area_filter_3",
}

export enum ProjectFloorFilter {
  floor_filter_1 = "floor_filter_1",
  floor_filter_2 = "floor_filter_2",
  floor_filter_3 = "floor_filter_3",
}

export interface ProjectFiltersStore {
  area: Set<ProjectAreaFilter>
  numberOfFloors: Set<ProjectFloorFilter>
}

export const projectFiltersStore = makeObservableStore<ProjectFiltersStore>({
  area: new Set(),
  numberOfFloors: new Set(),
})

export function resetProjectFiltersStore() {
  console.log("Reset project filters store")

  runInAction(() => {
    projectFiltersStore.area.clear()
    projectFiltersStore.numberOfFloors.clear()
  })
}
