import { lazy } from "react"
import { Outlet } from "react-router-dom"
import { MainLayout } from "../layouts/main"

// ----------------------------------------------------------------------

export const ProjectListPage = lazy(() => import("pages").then(module => ({ default: module.ProjectListPage })))
export const ProjectDetailsPage = lazy(() => import("pages").then(module => ({ default: module.ProjectDetailsPage })))

export const ArticleListPage = lazy(() => import("pages").then(module => ({ default: module.ArticleListPage })))
export const ArticleDetailsPage = lazy(() => import("pages").then(module => ({ default: module.ArticleDetailsPage })))

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet/>
      </MainLayout>
    ),
    children: [
      {
        path: "projects",
        children: [
          { element: <ProjectListPage/>, index: true },
          { path: ":id", element: <ProjectDetailsPage/> },
        ],
      },
      {
        path: "blog",
        children: [
          { element: <ArticleListPage/>, index: true },
          { path: ":id", element: <ArticleDetailsPage/> },
        ],
      },
    ],
  },
]
