import { runInAction } from "mobx"
import { makeObservableStore } from "../../shared/utils"

export interface FormModalStore {
  isOpened: boolean
  isLoading: boolean
  isSuccess: boolean
  error: string | null
  topic: string | undefined
  tag: string | undefined
}

export const formModalStore = makeObservableStore<FormModalStore>({
  isOpened: false,
  isLoading: false,
  isSuccess: false,
  error: null,
  topic: undefined,
  tag: undefined,
})

export function resetFormModalStore() {
  runInAction(() => {
    formModalStore.isLoading = false
    formModalStore.isSuccess = false
    formModalStore.error = null
    formModalStore.topic = undefined
    formModalStore.tag = undefined
  })
}
