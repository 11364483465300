import { umbrellaException } from "../../shared/lib/umbrellaException"
import { runInAction } from "mobx"
import { GalleryStore, galleryStore } from "../store"
import { getGallery, updateGallery } from "../../shared/api/gallery"
import { Gallery } from "../../shared/types/Gallery"

class GalleryServiceImpl {

  async load(): Promise<Gallery | undefined> {
    try {
      runInAction(() => {
        this.galleryStore.gallery.images = []
        this.galleryStore.isLoading = true
        this.galleryStore.error = null
      })

      const { data: { totalCount, payload } } = await getGallery()

      runInAction(() => {
        this.galleryStore.totalCount = totalCount
        this.galleryStore.gallery = payload
      })
      return payload
    } catch (e) {
      this.galleryStore.error = umbrellaException(e)
    } finally {
      runInAction(() => {
        this.galleryStore.isLoading = false
      })
    }
  }

  async update(data: FormData): Promise<number | undefined> {
    try {
      runInAction(() => {
        this.galleryStore.isLoading = true
        this.galleryStore.error = null
      })

      const { data: { status } } = await updateGallery(data)
      void this.load()

      return status
    } catch (err) {
      runInAction(() => {
        this.galleryStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.galleryStore.isLoading = false
      })
    }
  }

  constructor(
    private galleryStore: GalleryStore,
  ) {
  }
}

export const GalleryService = new GalleryServiceImpl(galleryStore)
