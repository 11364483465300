import { observer } from "mobx-react-lite"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Drawer, Burger, createStyles, rem, Button, Stack } from "@mantine/core"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import { MainNavigateRoute, mainNavigateRoutes } from "shared/lib/constants"
import { Link as RSLink } from "react-scroll/modules"
import React, { useCallback } from "react"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  content: {
    paddingTop: `calc(${theme.spacing.xl} * 1)`,
    paddingBottom: `calc(${theme.spacing.xl} * 1)`,
    position: "relative",

    [theme.fn.smallerThan("xs")]: {
      borderRadius: 0,
    },
  },

  close: {
    position: "absolute",
    right: 26,
    top: 43,
    zIndex: 2001,
  },

  header: {
    padding: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },

  title: {
    fontSize: rem(32),
    fontWeight: 700,
  },

  body: {
    padding: theme.spacing.xl,
    paddingTop: 0,
  },

  link: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    marginBottom: theme.spacing.xs,
    borderRadius: theme.radius.lg,
    fontWeight: 500,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}))

// ----------------------------------------------------------------------

export const MobileNav = observer(function MobileNav() {
  const { classes, theme } = useStyles()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const matches = useMediaQuery(`(max-width: 1240px)`)
  const [ opened, { toggle, close } ] = useDisclosure(false)

  const openLink = useCallback((link: MainNavigateRoute) => {
    close()

    const isHomePage = pathname === "/"

    if (isHomePage || !link.internal) {
      return
    }

    navigate(`/#${link.linkTo}`)

    setTimeout(() => {
      const galleryElement = document.getElementById(link.linkTo)
      if (galleryElement) {
        galleryElement.scrollIntoView({ behavior: "smooth" })
      }
    }, 500)

  }, [ pathname, navigate ])

  return (
    <>
      {matches && <Burger ml={16} opened={opened} onClick={toggle} size="md"/>}

      <Drawer.Root position="right" opened={opened} onClose={close}>
        <Drawer.Overlay/>
        <Drawer.Content className={classes.content}>
          <Drawer.CloseButton radius="lg" size="xl" className={classes.close}/>
          <Drawer.Header className={classes.header}>
            <Drawer.Title className={classes.title}>Меню</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body className={classes.body}>
            <Stack align="flex-start">
              {mainNavigateRoutes.map((link) => {
                const { label, Icon, linkTo, internal } = link

                if (internal) {
                  return (
                    <Button
                      leftIcon={<Icon stroke={1.5}/>}
                      className={classes.link}
                      key={linkTo}
                      variant="subtle"
                      radius="lg"
                      size="md"
                      component={RSLink}
                      hashSpy={true}
                      spy={true}
                      to={linkTo}
                      smooth={true}
                      duration={1000}
                      href={`#${linkTo}`}
                      onClick={() => openLink(link)}
                    >
                      {label}
                    </Button>
                  )
                }

                return (
                  <Button
                    leftIcon={<Icon stroke={1.5}/>}
                    className={classes.link}
                    variant={linkTo === pathname ? "light" : "subtle"}
                    color={linkTo === pathname ? theme.primaryColor : "blue"}
                    key={linkTo}
                    radius="lg"
                    size="md"
                    component={Link}
                    to={linkTo}
                    onClick={close}
                  >
                    {label}
                  </Button>
                )

              })}
            </Stack>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
})
