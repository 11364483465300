import { lazy } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { AuthGuard } from "app/hocs/AuthGuard"
import { DashboardLayout } from "../layouts/dashboard"
import { RoleBasedGuard } from "../hocs/RoleBasedGuard"

// ----------------------------------------------------------------------

export const ProjectTablePage = lazy(() => import("pages").then(module => ({ default: module.ProjectTablePage })))
export const ProjectEditPage = lazy(() => import("pages").then(module => ({ default: module.ProjectEditPage })))

export const GalleryEditPage = lazy(() => import("pages").then(module => ({ default: module.GalleryEditPage })))

export const ArticleTablePage = lazy(() => import("pages").then(module => ({ default: module.ArticleTablePage })))
export const ArticleEditPage = lazy(() => import("pages").then(module => ({ default: module.ArticleEditPage })))

export const EmployeeTablePage = lazy(() => import("pages").then(module => ({ default: module.EmployeeTablePage })))
export const EmployeeEditPage = lazy(() => import("pages").then(module => ({ default: module.EmployeeEditPage })))

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Outlet/>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="/dashboard/projects" replace/>, index: true },
      {
        path: "projects",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ProjectTablePage/>
              </RoleBasedGuard>
            ), index: true,
          },
          {
            path: "create",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ProjectEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: ":id",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ProjectEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "gallery",
        element: (
          <RoleBasedGuard roles={[ "admin" ]}>
            <GalleryEditPage/>
          </RoleBasedGuard>
        ),
      },
      {
        path: "blog",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ArticleTablePage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "create",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ArticleEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: ":id",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ArticleEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "team",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <EmployeeTablePage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "create",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <EmployeeEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: ":id",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <EmployeeEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
    ],
  },
]
