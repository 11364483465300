import { CalculatorStore, calculatorStore, defaultCalculatorInfo } from "../store"
import { runInAction } from "mobx"
import { sendCalculatorRequest } from "../../shared/api/crm"
import { AxiosError } from "axios"

export type CalculatorAreaKey = "upTo100" | "from100to150" | "from150to200" | "from200to300" | "from300to400" | "more400"

export const CalculatorArea: Record<CalculatorAreaKey, string> =  {
  upTo100: "До 100 м2",
  from100to150: "100 м2 - 150 м2",
  from150to200: "150 м2 - 200 м2",
  from200to300: "200 м2 - 300 м2",
  from300to400: "300 м2 - 400 м2",
  more400: "Более 400 м2",
}

export const CalculatorYard =  {
  upTp5: "До 5 соток",
  upTp10: "До 10 соток",
  more10: "Более 10 соток",
  none: "Еще нет участка",
}

export const CalculatorBuildStart =  {
  now: "Готов начать сейчас, чтобы получить ключи через 4-6 месяцев",
  "after2-3": "Хочу начать строительство через 2-3 месяца",
  after6: "Хочу начать примерно через полгода",
  planOnly: "Пока только планирую строительство",
}

export const CalculatorHouseType =  {
  "brick-neoclassical": "Кирпичная неоклассика",
  "wright-prairie": "Райт (прерий)",
  "traditional": "Традиционный",
  "minimalism": "Минимализм",
}

class CalculatorServiceImpl {
  private readonly numberOfSteps = 8

  async sendData(form: { phone: string, fullName: string }): Promise<void> {
    try {
      runInAction(() => {
        this.calculatorStore.isLoading = true
        this.calculatorStore.error = null
      })

      const sendData = {
        phone: form.phone,
        fullName: form.fullName,
        houseType: `Стиль дома: ${CalculatorHouseType[this.calculatorStore.info.houseType!]}`,
        numberOfFloors: `Этажей: ${this.calculatorStore.info.numberOfFloors!}`,
        area: `Площадь: ${CalculatorArea[this.calculatorStore.info.area!]}`,
        yardSize: `Размер участка: ${CalculatorYard[this.calculatorStore.info.yardSize!]}`,
        buildStart: `Начало строительства: ${CalculatorBuildStart[this.calculatorStore.info.buildStart!]}`,
        components: `Комплектация: ${this.prepareComponents(this.calculatorStore.info.components)}`,
      }

      await sendCalculatorRequest(sendData)
      this.goForward()
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response && err.response.data && err.response.data.message) {
          this.calculatorStore.error = err.response.data.message
          return
        }
        this.calculatorStore.error = "К сожалению, произошла ошибка по неизвестным нам причинам. Пожалуйста, попробуйте отправить завку позже"
        return
      }
      this.calculatorStore.error = "К сожалению, произошла ошибка по неизвестным нам причинам. Пожалуйста, попробуйте отправить завку позже"
    } finally {
      this.calculatorStore.isLoading = false
    }
  }

  private prepareComponents(components: CalculatorStore["info"]["components"]): string {
    const { attic, terrace, garage, groundFloor } = components
    const result: Array<string> = []

    if (groundFloor) {
      result.push("Цокольный этаж")
    }

    if (garage) {
      result.push("Гараж")
    }

    if (terrace) {
      result.push("Терраса")
    }

    if (attic) {
      result.push("Мансарда")
    }

    return result.join(", ")
  }

  goBack(): void {
    runInAction(() => {
      const currentStep = this.calculatorStore.currentStep

      if (currentStep !== 1) {
        this.calculatorStore.currentStep = currentStep - 1
      }
    })
  }

  goForward(): void {
    runInAction(() => {
      const currentStep = this.calculatorStore.currentStep

      if (currentStep !== this.numberOfSteps) {
        this.calculatorStore.currentStep = currentStep + 1
      }
    })
  }

  setHouseType(value: keyof typeof CalculatorHouseType): void {
    runInAction(() => {
      this.calculatorStore.info = {
        ...defaultCalculatorInfo,
        houseType: value,
      }
    })

    this.goForward()
  }

  setArea(value: CalculatorAreaKey): void {
    runInAction(() => {
      this.calculatorStore.info.area = value
    })
  }

  setFloors(value: number): void {
    runInAction(() => {
      this.calculatorStore.info.numberOfFloors = value
    })
  }

  setYardSize(value: keyof typeof CalculatorYard): void {
    runInAction(() => {
      this.calculatorStore.info.yardSize = value
    })
  }

  setBuildStart(value: keyof typeof CalculatorBuildStart): void {
    runInAction(() => {
      this.calculatorStore.info.buildStart = value
    })
  }

  setComponent(component: keyof CalculatorStore["info"]["components"], state: boolean): void {
    runInAction(() => {
      this.calculatorStore.info.components[component] = state
    })
  }

  constructor(
    private calculatorStore: CalculatorStore,
  ) {
  }
}

export const CalculatorService = new CalculatorServiceImpl(calculatorStore)
