export const API_ENDPOINTS = {
  auth: {
    login: "/api/v1/auth/login",
    logout: "/api/v1/auth/logout",
    refresh: "/api/v1/auth/refresh",
  },
  project: "/api/v1/project",
  gallery: "/api/v1/gallery",
  article: "/api/v1/article",
  employee: "/api/v1/employee",
  crm: "/api/v1/crm",
}
