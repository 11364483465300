import { EmployeeStore, employeeStore } from "../store"
import { umbrellaException } from "../../shared/lib/umbrellaException"
import { runInAction } from "mobx"
import { createEmployee, getEmployeeById, getTeam, removeEmployee, updateEmployee } from "../../shared/api/employee"
import { Employee } from "../../shared/types/Employee"

class EmployeeServiceImpl {

  async load(): Promise<void> {
    try {
      runInAction(() => {
        this.employeeStore.team = []
        this.employeeStore.isLoading = true
        this.employeeStore.error = null
      })

      const { data: { totalCount, payload } } = await getTeam()

      runInAction(() => {
        this.employeeStore.totalCount = totalCount
        this.employeeStore.team = payload
      })
    } catch (e) {
      this.employeeStore.error = umbrellaException(e)
    } finally {
      runInAction(() => {
        this.employeeStore.isLoading = false
      })
    }
  }

  async getById(id: string): Promise<Employee | undefined> {
    try {
      runInAction(() => {
        this.employeeStore.isLoading = true
        this.employeeStore.error = null
      })

      const { data: { payload } } = await getEmployeeById(id)

      return payload
    } catch (err) {
      runInAction(() => {
        this.employeeStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.employeeStore.isLoading = false
      })
    }
  }

  async create(data: FormData): Promise<number | undefined> {
    try {
      runInAction(() => {
        this.employeeStore.isLoading = true
        this.employeeStore.error = null
      })

      const { data: { status } } = await createEmployee(data)
      void this.load()

      return status
    } catch (err) {
      runInAction(() => {
        this.employeeStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.employeeStore.isLoading = false
      })
    }
  }

  async update(id: string, data: FormData): Promise<number | undefined> {
    try {
      runInAction(() => {
        this.employeeStore.isLoading = true
        this.employeeStore.error = null
      })

      const { data: { status } } = await updateEmployee(id, data)
      void this.load()

      return status
    } catch (err) {
      runInAction(() => {
        this.employeeStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.employeeStore.isLoading = false
      })
    }
  }

  async remove(id: string): Promise<void> {
    try {
      runInAction(() => {
        this.employeeStore.isLoading = true
        this.employeeStore.error = null
      })

      await removeEmployee(id)
      void this.load()

      runInAction(() => {
        this.employeeStore.team = this.employeeStore.team.filter((employee) => employee.id !== id)
        this.employeeStore.totalCount -= 1
      })
    } catch (err) {
      runInAction(() => {
        this.employeeStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.employeeStore.isLoading = false
      })
    }
  }

  constructor(
    private employeeStore: EmployeeStore,
  ) {
  }
}

export const EmployeeService = new EmployeeServiceImpl(employeeStore)
