import { Link, useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { createStyles, Header as MantineHeader, Group, Container, rem, Button } from "@mantine/core"
import { Logo } from "shared/ui/Logo"
import { MainNavigateRoute, mainNavigateRoutes } from "shared/lib/constants"
import React, { useCallback } from "react"
import { MobileNav } from "./MobileNav"
import { useMediaQuery } from "@mantine/hooks"
import { Link as RSLink } from "react-scroll"
import { FormModalService } from "../../app/service"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  inner: {
    height: rem(80),
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  logo: {
    top: -22,
    position: "relative",
    marginRight: "auto",

    [theme.fn.smallerThan(900)]: {
      top: 0,
    },
  },

  link: {
    padding: `${rem(10)} ${rem(16)}`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  phone: {
    fontSize: 20,
    fontWeight: 700,
  },
}))

// ----------------------------------------------------------------------

export const Header = observer(function Header() {
  const { theme, classes } = useStyles()
  const { pathname } = useLocation()
  const matches = useMediaQuery(`(max-width: 1240px)`)
  const navigate = useNavigate()

  const openModal = () => FormModalService.setIsOpened(true, "Обратный звонок", "консультация")

  const openLink = useCallback((link: MainNavigateRoute) => {
    const isHomePage = pathname === "/"

    if (isHomePage || !link.internal) {
      return
    }

    navigate(`/#${link.linkTo}`)

    setTimeout(() => {
      const galleryElement = document.getElementById(link.linkTo)
      if (galleryElement) {
        galleryElement.scrollIntoView({ behavior: "smooth" })
      }
    }, 500)

  }, [ pathname, navigate ])

  return (
    <MantineHeader zIndex={150} withBorder={false} height={80}>
      <Container size={1440}>
        <div className={classes.inner}>
          <Logo className={classes.logo}/>

          {!matches && (
            <Group spacing={5}>
              {mainNavigateRoutes.map((link) => {
                const { label, linkTo, internal } = link

                if (internal) {
                  return (
                    <Button
                      className={classes.link}
                      key={linkTo}
                      variant={linkTo === pathname ? "light" : "subtle"}
                      color={linkTo === pathname ? theme.primaryColor : "blue"}
                      radius="lg"
                      size="md"
                      component={RSLink}
                      hashSpy={true}
                      spy={true}
                      to={linkTo}
                      smooth={true}
                      duration={1000}
                      href={`#${linkTo}`}
                      onClick={() => openLink(link)}
                    >
                      {label}
                    </Button>
                  )
                }

                return (
                  <Button
                    className={classes.link}
                    key={linkTo}
                    variant={linkTo === pathname ? "light" : "subtle"}
                    color={linkTo === pathname ? theme.primaryColor : "blue"}
                    radius="lg"
                    size="md"
                    component={Link}
                    to={linkTo}
                  >
                    {label}
                  </Button>
                )
              })}
            </Group>
          )}

          <Button
            variant="gradient"
            gradient={{ from: "#26b0ba", to: "#2f70a7", deg: 50 }}
            ml={16}
            size={matches ? "sm" : "md"}
            radius="lg"
            onClick={openModal}
          >
            Обратный звонок
          </Button>

          <MobileNav/>
        </div>
      </Container>
    </MantineHeader>
  )
})
