import { type Project, type ProjectParams } from "shared/types/Project"
import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type ErrorResponse, GeneralResponse } from "../types/common"

// ----------------------------------------------------------------------

export function getProjects(params?: Partial<ProjectParams>): Promise<AxiosResponse<GeneralResponse<Array<Project>>, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.project}/list`, { params })
}

export function getProjectById(id: string): Promise<AxiosResponse<GeneralResponse<Project>, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.project}/${id}`)
}

export function updateProject(id: string, body: FormData): Promise<AxiosResponse<GeneralResponse<Project>, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.project}/${id}`, body)
}

export function createProject(body: FormData): Promise<AxiosResponse<GeneralResponse<Project>, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.project}/create`, body)
}

export function removeProject(id: string): Promise<AxiosResponse<GeneralResponse, ErrorResponse>> {
  return api.delete(`${API_ENDPOINTS.project}/${id}`)
}
