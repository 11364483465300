import { makeObservableStore } from "../../../shared/utils"
import { Employee } from "../../../shared/types/Employee"
import { runInAction } from "mobx"

export interface EmployeeStore {
  isLoading: boolean
  error: string | null
  totalCount: number
  team: Array<Employee>
}

export const employeeStore = makeObservableStore<EmployeeStore>({
  isLoading: false,
  error: null,
  totalCount: 0,
  team: [],
})

export function resetEmployeeStoreStore() {
  runInAction(() => {
    employeeStore.isLoading = false
    employeeStore.error = null
    employeeStore.totalCount = 0
    employeeStore.team = []
  })
}
