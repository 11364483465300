export enum ArticleCategory {
  roofing = "roofing",
  finishing = "finishing",
  masonry = "masonry",
}

export interface Article {
  id: string
  title: string
  description: string
  content: string
  cover: string
  category: ArticleCategory
  published: boolean
  onMain: boolean
  createdAt: string
}

export type ArticleParams = {
  categories?: string
  onMain?: boolean
  page: number
  perPage: number
  search?: string
};
