import { lazy, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Navigate, useRoutes } from "react-router-dom"
import { useScrollToTop } from "shared/hooks"

import { MainLayout } from "../layouts/main"
import { dashboardRoutes } from "./dashboard"
import { authRoutes } from "./auth"
import { errorRoutes } from "./error"
import { mainRoutes } from "./main"
import { AuthStore } from "../store"

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import("pages").then(module => ({ default: module.HomePage })))

// ----------------------------------------------------------------------

export const Router = observer(function Router() {
  useScrollToTop()

  const { checkAuth } = AuthStore

  useEffect(() => {
    void checkAuth()
  }, [])

  return useRoutes([
    {
      path: "/",
      element: (
        <MainLayout>
          <HomePage/>
        </MainLayout>
      ),
    },

    ...mainRoutes,
    ...authRoutes,
    ...dashboardRoutes,
    ...errorRoutes,

    { path: "*", element: <Navigate to="/error/404" replace/> },
  ])
})
