import { type ArticleParams, type Article } from "shared/types/article"
import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type  ErrorResponse, GeneralResponse } from "../types/common"

// ----------------------------------------------------------------------

export function getArticles(params?: Partial<ArticleParams>): Promise<AxiosResponse<GeneralResponse<Array<Article>>, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.article}/list`, { params })
}

export function getArticleById(id: string): Promise<AxiosResponse<GeneralResponse<Article>, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.article}/${id}`)
}

export function updateArticle(id: string, body: FormData): Promise<AxiosResponse<GeneralResponse<Article>, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.article}/${id}`, body)
}

export function toggleArticlePublish(id: string, published: boolean): Promise<AxiosResponse<GeneralResponse<Article>, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.article}/${id}`, { published })
}

export function createArticle(body: FormData): Promise<AxiosResponse<GeneralResponse<Article>, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.article}/create`, body)
}

export function removeArticle(id: string): Promise<AxiosResponse<GeneralResponse, ErrorResponse>> {
  return api.delete(`${API_ENDPOINTS.article}/${id}`)
}

export function toggleOnMain(id: string, onMain: boolean): Promise<AxiosResponse<GeneralResponse, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.article}/toggle-on-main`, { id, onMain })
}
