import "./index.css"
import "@fontsource/mulish/400.css"
import "@fontsource/mulish/500.css"
import "@fontsource/mulish/600.css"
import "@fontsource/mulish/700.css"
import "@fontsource/mulish/900.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import "dayjs/locale/ru"

/* lightbox */
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/captions.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"

/* carousel */
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { HelmetProvider } from "react-helmet-async"
import dayjs from "dayjs"
import { DatesProvider } from "@mantine/dates"
import { Notifications } from "@mantine/notifications"
import { useLocalStorage } from "@mantine/hooks"
import { ModalsProvider } from "@mantine/modals"
import { MantineProvider, ColorSchemeProvider, type ColorScheme } from "@mantine/core"
import React, { Suspense } from "react"
import { BrowserRouter } from "react-router-dom"

import { BackToTop } from "shared/ui/BackToTop"
import { LoadingScreen } from "../shared/ui/LoadingScreen"
import { Router } from "./routes"
import { ConsentPersonalDataModal, PrivacyModal } from "../features/docModal"
import { FormModal } from "../features/FormModal"

// ----------------------------------------------------------------------
dayjs.locale("ru")

export function App(): React.JSX.Element {
  const [ colorScheme, setColorScheme ] = useLocalStorage<ColorScheme>({ key: "color-scheme", defaultValue: "light" })
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"))

  const theme = {
    fontFamily: "Mulish, sans-serif",
    colorScheme,

    shadows: {
      light: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
    },

    components: {
      Modal: {
        defaultProps: {
          radius: "lg",
        },
      },
    },
  }

  return (
    <HelmetProvider>
      <DatesProvider settings={{ locale: "ru" }}>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
            <ModalsProvider>
              <BrowserRouter>
                <Suspense fallback={<LoadingScreen/>}>
                  <Router/>
                  <ConsentPersonalDataModal/>
                  <PrivacyModal/>
                  <FormModal/>
                </Suspense>
              </BrowserRouter>
              <Notifications/>
              <BackToTop/>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </DatesProvider>
    </HelmetProvider>
  )
}
